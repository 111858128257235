import React, { useState, useContext } from 'react'

import {
  Box,
  Flex,
  Text,
  Button,
  useToast,
  Input,
  Link
} from '@chakra-ui/react'

import { AuthContext } from "../Auth";
import app from '../firebase'
import { useHistory } from 'react-router-dom'

const ForgotPass = () => {
  const [email, setEmail] = useState('')
  const toast = useToast()
  const history = useHistory()

  const handleChange = (event) => {
    const value = event.target.value
    setEmail(value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email === '') {
      toast({
        title: "Uh Oh :(",
        description: "All fields must be filled in",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } else {
      try {
        await app.auth().sendPasswordResetEmail(email).then(() => {
          toast({
            title: "",
            description: "Email sent!",
            status: "success",
            duration: 5000,
            isClosable: true,
          })
        })
        history.push('/login')
      } catch (err) {
        const error = JSON.stringify(err)
        if (error.includes("There is no user record")) {
          toast({
            title: "Uh Oh :(",
            description: "Invalid email",
            status: "error",
            duration: 5000,
            isClosable: true,
          })
        } else if (error.includes("The email address is badly formatted.")) {
          toast({
            title: "Uh Oh :(",
            description: "The email address is badly formatted",
            status: "error",
            duration: 5000,
            isClosable: true,
          })
        } else {
          toast({
            title: "Uh Oh :(",
            description: "An error has occurred",
            status: "error",
            duration: 5000,
            isClosable: true,
          })
        }
      }
    }
  }

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    history.push('/')
  }
  return (
    <>
      <Box w="100%" h="100vh" overflow="auto" bg="gray.800">
        <Box align='center' bg='gray.800' w={['100%', '100%', '100%', '100%']} left={['50%', '50%', '50%', '50%']} top='20%'>
          <Text fontWeight='200' color='white' fontSize={[60, 60, 70, 80]}>Reset Password</Text>
          <form onSubmit={handleSubmit}>
            <Flex align='center' flexDir='column'>
              <Input name='email' onChange={handleChange} value={email} color='white' w={['70%', '50%', '40%', '30%']} mt='2%' variant='flushed' placeholder="Email" />
              <Button mb={['5%', '4%', '3%', '1%']} fontSize={20} colorScheme='cyan' w={['50%', '40%', '30%', '20%']} mt='2%' type='submit' >Reset Password</Button>
              <Link color="cyan" href="/login">
                Login
              </Link>
            </Flex>
          </form>
        </Box>
      </Box>
    </>
  )
}

export default ForgotPass