import React, { useState, useEffect } from 'react'

import {
  Text,
  Box,
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  useToast
} from '@chakra-ui/react'

import {
  ChevronLeftIcon
} from '@chakra-ui/icons'

import app from '../firebase'
import { useHistory } from 'react-router-dom'

const database = app.database()
class Admin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      users: []
    }
  }

  componentDidMount() {
    const main = async () => {
      await database.ref('/').orderByKey().on('child_added', async (snapshot) => {
        this.setState(prevState => ({
          users: [
            ...prevState.users,
            {
              key: snapshot.key,
              displayName: snapshot.val().user.displayName,
              email: snapshot.val().user.email,
            }
          ]
        }))
      })
    }

    main()
  }

  componentWillUnmount() {
    database.ref('/').off()
  }

  render() {
    return (
      <>
        <Display users={this.state.users} />
      </>
    )
  }
}

const Display = (props) => {
  const history = useHistory()
  const toast = useToast()
  const [notification, setNotification] = useState({
    title: '',
    body: ''
  })
  const { isOpen, onOpen, onClose } = useDisclosure()
  useEffect(() => {
    const main = async () => {
      const email = await app.auth().currentUser.email
      if (email !== 'admin@admin.com') {
        history.push('/')
      }
    }
    main()
  }, [history])

  const signOutUser = () => {
    app.auth().signOut();
  }

  const handleNotification = async () => {
    if (notification.title !== '' && notification.body !== '') {
      await app.database().ref('/').on('child_added', async snapshot => {
        await app.database().ref(`${snapshot.key}/notifications`).push({
          title: notification.title,
          body: notification.body,
          date: JSON.stringify(Date.now()),
          viewed: false
        })
      })
      setNotification({
        title: '',
        body: '',
      })
      onClose()
      // app.database().ref('/').off()
    } else {
      toast({
        title: "Uh Oh :(",
        description: "Please make sure to fill all fields",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const handleNotifChange = (event) => {
    const value = event.target.value
    setNotification({
      ...notification,
      [event.target.name]: value
    })
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg='gray.800'>
          <ModalHeader color='white'>New Notification</ModalHeader>
          <ModalCloseButton color='white' />
          <ModalBody>
            <form onSubmit={handleNotification}>
              <Input name='title' value={notification.title} onChange={handleNotifChange} color='white' variant='flushed' placeholder='Title' />
              <Input name='body' value={notification.body} onChange={handleNotifChange} mt='5%' color='white' variant='flushed' placeholder='Body' />
            </form>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="cyan" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button onClick={handleNotification} colorScheme='red' variant="ghost">Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box w="100%" h="100vh" overflow="auto" bg="gray.800">
        <ChevronLeftIcon pos='absolute' _hover={{cursor: 'pointer'}} onClick={() => history.push('/')} w={45} h={45} color='white' />
        <Button left={['35%', '60%', '65%', '72%']} w={['35%', '20%', '20%', '10%']} mt='2%' flexDir='end' onClick={onOpen} colorScheme="cyan">New Notification</Button>
        <Button left={['43%', '63%', '68%', '75%']} w={['20%', '15%', '10%', '8%']} mt='2%' flexDir='end' onClick={signOutUser} colorScheme="red">Logout</Button>
        <Text fontWeight={200} color='white' fontSize={30} textAlign='center'>Users</Text>
        <Table mt='2%' borderWidth={5} borderRadius={50} borderColor='blue.800' variant='unstyled' align='center' mb='5%' w='50%' >
          <Thead >
            <Tr >
              <Th color='blue.600'>#</Th>
              <Th color='blue.600'>Name</Th>
              <Th color='blue.600'>Email</Th>
            </Tr>
          </Thead>
          <Tbody color='white'>
            {/* <Tr>
              <Td>inches</Td>
              <Td>millimetres (mm)</Td>
              <Td>25.4</Td>
            </Tr>
            <Tr>
              <Td>feet</Td>
              <Td>centimetres (cm)</Td>
              <Td>30.48</Td>
            </Tr>
            <Tr>
              <Td>yards</Td>
              <Td>metres (m)</Td>
              <Td>0.91444</Td>
            </Tr> */}
            {props.users.map((data, index) => (
              <Tr color='blue.50'>
                <Td color='blue.100'>{index}</Td>
                <Td color='blue.100'>{data.displayName}</Td>
                <Td wordBreak='break-all' color='blue.100'>{data.email}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </>
  )
}

export default Admin